@font-face {
	font-family: "Titillium";
	src: url("../../assets/fonts/TitilliumWeb-Regular.eot");
	src: url("../../assets/fonts/TitilliumWeb-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/TitilliumWeb-Regular.woff2") format("woff2"),
		url("../../assets/fonts/TitilliumWeb-Regular.woff") format("woff"),
		url("../../assets/fonts/TitilliumWeb-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Titillium";
	src: url("../../assets/fonts/TitilliumWeb-Bold.eot");
	src: url("../../assets/fonts/TitilliumWeb-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/TitilliumWeb-Bold.woff2") format("woff2"),
		url("../../assets/fonts/TitilliumWeb-Bold.woff") format("woff"),
		url("../../assets/fonts/TitilliumWeb-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Titillium";
	src: url("../../assets/fonts/TitilliumWeb-Italic.eot");
	src: url("../../assets/fonts/TitilliumWeb-Italic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/TitilliumWeb-Italic.woff2") format("woff2"),
		url("../../assets/fonts/TitilliumWeb-Italic.woff") format("woff"),
		url("../../assets/fonts/TitilliumWeb-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Titillium";
	src: url("../../assets/fonts/TitilliumWeb-SemiBold.eot");
	src: url("../../assets/fonts/TitilliumWeb-SemiBold.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/TitilliumWeb-SemiBold.woff2") format("woff2"),
		url("../../assets/fonts/TitilliumWeb-SemiBold.woff") format("woff"),
		url("../../assets/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Titillium";
	src: url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.eot");
	src: url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.woff2") format("woff2"),
		url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.woff") format("woff"),
		url("../../assets/fonts/TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
	font-display: block;
}
