.markdown li {
	@apply list-inside list-disc mb-2;
}
.markdown li li {
	@apply list-inside list-none mb-0;
}
.markdown li ul {
	@apply ml-4;
}
.markdown li li:before {
	content: '-';
	@apply mr-2;
}

.markdown li:has(ul){
	@apply font-semibold;
}
.markdown li:has(ul) li {
	@apply font-normal;
}