.call-grid--host {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(3, 1fr);
	row-gap: 1rem;
	column-gap: 1rem;
	grid-template-areas:
		"user1 user2 user3 user4"
		"user5 user6 user7 user8"
		"user9 user10 user11 user12";
}
/*
Code for actionbar on the right
.call-grid--FULLSCREEN.wrapper.host.actionbar--COLLAPSED {
	width: calc(100vw - 100px);
}
.call-grid--FULLSCREEN.wrapper.host.actionbar--EXPANDED {
	width: calc(100vw - 300px);
}
*/

.call-grid--FULLSCREEN.wrapper.host.actionbar--COLLAPSED {
	height: calc(100vh - 100px);
}
.call-grid--FULLSCREEN.wrapper.host.actionbar--EXPANDED {
	height: calc(100vh - 300px);
}

.call-grid--FULLSCREEN.wrapper {
	@apply fixed w-screen h-screen p-10 left-0 top-0 z-40;
}
/*
.call-grid--FULLSCREEN.wrapper.host.actionbar--EXPANDED {
	@apply mr-48;
}
.call-grid--FULLSCREEN.wrapper.host.actionbar--COLLAPSED {
	@apply mr-10;
}
*/

.call-grid--FULLSCREEN.wrapper.host.actionbar--EXPANDED {
	@apply mb-48;
}
.call-grid--FULLSCREEN.wrapper.host.actionbar--COLLAPSED {
	@apply mb-10;
}

.call-grid--FULLSCREEN .inner {
	@apply p-10 bg-white rounded-lg w-full relative h-full;
}
/*
.call-grid--FULLSCREEN .shadow-grid,
.call-grid--FULLSCREEN .inner {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-template-rows: 1fr 1fr 1fr 1fr 80px; 
	row-gap: 1rem;
	column-gap: 1rem;
	grid-template-areas:
		"user10 Host Host Host user11"
		"user8 Host Host Host user9"
		"user6 Host Host Host user7"
		"user1 user2 user3 user4 user5"
		"controls controls controls controls controls";
}
.call-grid--FULLSCREEN .inner::before {
	content: "";
	background-color: #fcfcfc;

	grid-column: 3;
	grid-row: 1;
}

.call-grid--FULLSCREEN .call-controls {
	grid-area: controls;
}
.call-grid--FULLSCREEN .host-actions {
	grid-area: controls2;
}
.call-grid--FULLSCREEN .call-chat {
	grid-area: actions;
}

.call-grid--FULLSCREEN .host {
	grid-area: Host;
}

.call-grid--FULLSCREEN .user1 {
	grid-area: user1;
}

.call-grid--FULLSCREEN .user2 {
	grid-area: user2;
}

.call-grid--FULLSCREEN .user3 {
	grid-area: user3;
}

.call-grid--FULLSCREEN .user4 {
	grid-area: user4;
}

.call-grid--FULLSCREEN .user5 {
	grid-area: user5;
}

.call-grid--FULLSCREEN .user6 {
	grid-area: user6;
}

.call-grid--FULLSCREEN .user7 {
	grid-area: user7;
}

.call-grid--FULLSCREEN .user8 {
	grid-area: user8;
}

.call-grid--FULLSCREEN .user9 {
	grid-area: user9;
}

.call-grid--FULLSCREEN .user10 {
	grid-area: user10;
}

.call-grid--FULLSCREEN .user11 {
	grid-area: user11;
}

.call-grid--FULLSCREEN .user12 {
	grid-area: user12;
}
*/

.call-grid--FULLSCREEN  .inner {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(12, 1fr);
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
}
/* #region 1 */
.call-grid--FULLSCREEN.expected-1 .user1 { grid-area: 1 / 1 / 13 / 13; }
/* #endregion */

/* #region 2 */ 
.call-grid--FULLSCREEN.expected-2 .user1 { grid-area: 1 / 1 / 13 / 7; }
.call-grid--FULLSCREEN.expected-2 .user2 { grid-area: 1 / 7 / 13 / 13; }
/* #endregion */

/* #region 3 */
.call-grid--FULLSCREEN.expected-3 .user1 { grid-area: 1 / 1 / 7 / 7; }
.call-grid--FULLSCREEN.expected-3 .user2 { grid-area: 1 / 7 / 7 / 13; }
.call-grid--FULLSCREEN.expected-3 .user3 { grid-area: 7 / 4 / 13 / 10; }
/* #endregion */

/* #region 4 */
.call-grid--FULLSCREEN.expected-4 .user1 { grid-area: 1 / 1 / 7 / 7; }
.call-grid--FULLSCREEN.expected-4 .user2 { grid-area: 1 / 7 / 7 / 13; }
.call-grid--FULLSCREEN.expected-4 .user3 { grid-area: 7 / 1 / 13 / 7; }
.call-grid--FULLSCREEN.expected-4 .user4 { grid-area: 7 / 7 / 13 / 13; }
/* #endregion */

/* #region 5 */
.call-grid--FULLSCREEN.expected-5 .user1 { grid-area: 1 / 1 / 7 / 5; }
.call-grid--FULLSCREEN.expected-5 .user2 { grid-area: 1 / 5 / 7 / 9; }
.call-grid--FULLSCREEN.expected-5 .user3 { grid-area: 1 / 9 / 7 / 13; }
.call-grid--FULLSCREEN.expected-5 .user4 { grid-area: 7 / 3 / 13 / 7; }
.call-grid--FULLSCREEN.expected-5 .user5 { grid-area: 7 / 7 / 13 / 11; }
/* #endregion */

/* #region 6 */
.call-grid--FULLSCREEN.expected-6 .user1 { grid-area: 1 / 1 / 7 / 5; }
.call-grid--FULLSCREEN.expected-6 .user2 { grid-area: 1 / 5 / 7 / 9; }
.call-grid--FULLSCREEN.expected-6 .user3 { grid-area: 1 / 9 / 7 / 13; }
.call-grid--FULLSCREEN.expected-6 .user4 { grid-area: 7 / 1 / 13 / 5; }
.call-grid--FULLSCREEN.expected-6 .user5 { grid-area: 7 / 5 / 13 / 9; }
.call-grid--FULLSCREEN.expected-6 .user6 { grid-area: 7 / 9 / 13 / 13; }
/* #endregion */

/* #region 7 */
.call-grid--FULLSCREEN.expected-7 .user1 { grid-area: 1 / 1 / 7 / 4; }
.call-grid--FULLSCREEN.expected-7 .user2 { grid-area: 1 / 4 / 7 / 7; }
.call-grid--FULLSCREEN.expected-7 .user3 { grid-area: 1 / 7 / 7 / 10; }
.call-grid--FULLSCREEN.expected-7 .user4 { grid-area: 1 / 10 / 7 / 13; }
.call-grid--FULLSCREEN.expected-7 .user5 { grid-area: 7 / 1 / 13 / 5; }
.call-grid--FULLSCREEN.expected-7 .user6 { grid-area: 7 / 5 / 13 / 9; }
.call-grid--FULLSCREEN.expected-7 .user7 { grid-area: 7 / 9 / 13 / 13; }
/* #endregion */

/* #region 8 */
.call-grid--FULLSCREEN.expected-8 .user1 { grid-area: 1 / 1 / 7 / 4; }
.call-grid--FULLSCREEN.expected-8 .user2 { grid-area: 1 / 4 / 7 / 7; }
.call-grid--FULLSCREEN.expected-8 .user3 { grid-area: 1 / 7 / 7 / 10; }
.call-grid--FULLSCREEN.expected-8 .user4 { grid-area: 1 / 10 / 7 / 13; }
.call-grid--FULLSCREEN.expected-8 .user5 { grid-area: 7 / 1 / 13 / 4; }
.call-grid--FULLSCREEN.expected-8 .user6 { grid-area: 7 / 4 / 13 / 7; }
.call-grid--FULLSCREEN.expected-8 .user7 { grid-area: 7 / 7 / 13 / 10; }
.call-grid--FULLSCREEN.expected-8 .user8 { grid-area: 7 / 10 / 13 / 13; }
/* #endregion */

/* #region 9 */
.call-grid--FULLSCREEN.expected-9 .user1 { grid-area: 1 / 1 / 5 / 5; }
.call-grid--FULLSCREEN.expected-9 .user2 { grid-area: 1 / 5 / 5 / 9; }
.call-grid--FULLSCREEN.expected-9 .user3 { grid-area: 1 / 9 / 5 / 13; }
.call-grid--FULLSCREEN.expected-9 .user4 { grid-area: 5 / 1 / 9 / 5; }
.call-grid--FULLSCREEN.expected-9 .user5 { grid-area: 5 / 5 / 9 / 9; }
.call-grid--FULLSCREEN.expected-9 .user6 { grid-area: 5 / 9 / 9 / 13; }
.call-grid--FULLSCREEN.expected-9 .user7 { grid-area: 9 / 1 / 13 / 5; }
.call-grid--FULLSCREEN.expected-9 .user8 { grid-area: 9 / 5 / 13 / 9; }
.call-grid--FULLSCREEN.expected-9 .user9 { grid-area: 9 / 9 / 13 / 13; }
/* #endregion */

/* #region 10 */
.call-grid--FULLSCREEN.expected-10 .user1 { grid-area: 1 / 1 / 5 / 5; }
.call-grid--FULLSCREEN.expected-10 .user2 { grid-area: 1 / 5 / 5 / 9; }
.call-grid--FULLSCREEN.expected-10 .user3 { grid-area: 1 / 9 / 5 / 13; }
.call-grid--FULLSCREEN.expected-10 .user4 { grid-area: 5 / 1 / 9 / 4; }
.call-grid--FULLSCREEN.expected-10 .user5 { grid-area: 5 / 4 / 9 / 7; }
.call-grid--FULLSCREEN.expected-10 .user6 { grid-area: 5 / 7 / 9 / 10; }
.call-grid--FULLSCREEN.expected-10 .user7 { grid-area: 5 / 10 / 9 / 13; }
.call-grid--FULLSCREEN.expected-10 .user8 { grid-area: 9 / 1 / 13 / 5; }
.call-grid--FULLSCREEN.expected-10 .user9 { grid-area: 9 / 5 / 13 / 9; }
.call-grid--FULLSCREEN.expected-10 .user10 { grid-area: 9 / 9 / 13 / 13; }
/* #endregion */

/* #region 11 */
.call-grid--FULLSCREEN.expected-11 .user1 { grid-area: 1 / 1 / 5 / 5; }
.call-grid--FULLSCREEN.expected-11 .user2 { grid-area: 1 / 5 / 5 / 9; }
.call-grid--FULLSCREEN.expected-11 .user3 { grid-area: 1 / 9 / 5 / 13; }
.call-grid--FULLSCREEN.expected-11 .user4 { grid-area: 5 / 1 / 9 / 4; }
.call-grid--FULLSCREEN.expected-11 .user5 { grid-area: 5 / 4 / 9 / 7; }
.call-grid--FULLSCREEN.expected-11 .user6 { grid-area: 5 / 7 / 9 / 10; }
.call-grid--FULLSCREEN.expected-11 .user7 { grid-area: 5 / 10 / 9 / 13; }
.call-grid--FULLSCREEN.expected-11 .user8 { grid-area: 9 / 1 / 13 / 4; }
.call-grid--FULLSCREEN.expected-11 .user9 { grid-area: 9 / 4 / 13 / 7; }
.call-grid--FULLSCREEN.expected-11 .user10 { grid-area: 9 / 7 / 13 / 10; }
.call-grid--FULLSCREEN.expected-11 .user11 { grid-area: 9 / 10 / 13 / 13; }
/* #endregion */

/* #region 12 */
.call-grid--FULLSCREEN.expected-12 .user1 { grid-area: 1 / 1 / 5 / 4; }
.call-grid--FULLSCREEN.expected-12 .user2 { grid-area: 1 / 4 / 5 / 7; }
.call-grid--FULLSCREEN.expected-12 .user3 { grid-area: 1 / 7 / 5 / 10; }
.call-grid--FULLSCREEN.expected-12 .user4 { grid-area: 1 / 10 / 5 / 13; }
.call-grid--FULLSCREEN.expected-12 .user5 { grid-area: 5 / 1 / 9 / 4; }
.call-grid--FULLSCREEN.expected-12 .user6 { grid-area: 5 / 4 / 9 / 7; }
.call-grid--FULLSCREEN.expected-12 .user7 { grid-area: 5 / 7 / 9 / 10; }
.call-grid--FULLSCREEN.expected-12 .user8 { grid-area: 5 / 10 / 9 / 13; }
.call-grid--FULLSCREEN.expected-12 .user9 { grid-area: 9 / 1 / 13 / 4; }
.call-grid--FULLSCREEN.expected-12 .user10 { grid-area: 9 / 4 / 13 / 7; }
.call-grid--FULLSCREEN.expected-12 .user11 { grid-area: 9 / 7 / 13 / 10; }
.call-grid--FULLSCREEN.expected-12 .user12 { grid-area: 9 / 10 / 13 / 13; }
/* #endregion */

.call-grid--SIDEBAR.wrapper {
	@apply fixed bottom-0 left-0 top-0 m-5 mt-5 z-40;
	width: 20vmin;
}

.call-grid--SIDEBAR .inner {
	@apply w-full relative h-full;

	display: grid;
	grid-auto-columns: auto;
	/* grid-template-rows: repeat(6, 1fr);
	grid-auto-flow: column */
	grid-auto-rows: minmax(30px, 16vh);
	row-gap: 1rem;
	column-gap: 1rem;
}



.call-grid--SIDEBAR .call-layout-controls, .call-grid--FULLSCREEN .call-layout-controls {
	position: fixed;
	background-color: white;
	padding: 1rem 2rem;

	top: 20px;
	right: 20px;
}

.call-grid--FULLSCREEN .call-layout-controls {
	right: 20px;
}

.call-grid--SIDEBAR.host .call-controls, .call-grid--FULLSCREEN.host .call-controls {
	bottom: 100px;
}

.call-grid--SIDEBAR .call-controls, .call-grid--FULLSCREEN .call-controls {
	position: fixed;
	background-color: white;
	padding: 1rem 2rem;

	bottom: 20px;
	left: 50%;
	@apply transition-transform duration-300 ease-in-out transform -translate-x-1/2 rounded-md;
}
.call-controls.closed .buttons {
	visibility: hidden;
}

.call-controls.closed .label {
	opacity: 100;
	@apply translate-y-0;
}
.call-controls .label {
	transition: all 0.3s ease-out;

	opacity: 0;
}
.call-grid--SIDEBAR .call-controls.closed {
	@apply transition-all duration-300 ease-in-out transform -translate-x-1/2 h-5;
}

/* Place host as first in grid */
.call-grid--SIDEBAR .host {
	grid-row-start: 1;
	grid-column-start: 1;
}

.call-grid--SIDEBAR .host,
.call-grid--SIDEBAR div[class^="user"] {
	@apply border-white border-2;
}

div[class^="call-grid--"] .host,
div[class^="call-grid--"] .host video,
div[class^="call-grid--"] div[class^="user"],
div[class^="call-grid--"] div[class^="user"] video {
	@apply rounded-md overflow-hidden;
}

.call-grid--AUDIO_ONLY {
	@apply fixed z-40 bottom-0 left-0 ml-4 mb-4;
}
.call-grid--AUDIO_ONLY .inner {
	@apply bg-white bg-opacity-95 p-4 rounded-md;
}

.call-grid--AUDIO_ONLY .host,
.call-grid--AUDIO_ONLY .user1 {
	@apply hidden;
}
