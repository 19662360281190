/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

/*
input[type="text"] {
	@apply rounded-sm bg-black;
}
*/
.aspect-1-1 {
	aspect-ratio: 1 / 1;
}
/* CONFIG */
@import "styles/config/_theme.css";
@import "styles/config/_fonts.css";
@import "styles/config/_layers.css";
/* LAYOUT */
@import "styles/layout/_grids.css";
/* COMPONENTS */
@import "styles/components/_actionbar.css";
@import "styles/components/_chat.css";
@import "styles/components/_spinners.css";
@import "styles/components/_buttons.css";
@import "styles/components/_markdown.css";
/* UTILITIES */
@import "styles/utilities/_scrollbar.css";
@import "styles/utilities/_buttons.css";
@import "styles/utilities/_collections.css";
@import "styles/utilities/_animations.css";
@import "styles/utilities/_input.css";

/* Hide overlaying iframe... */
/* iframe {
	display: none !important;
} */