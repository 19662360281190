.btn {
	@apply inline-block text-center font-medium text-base p-3 rounded-sm cursor-pointer border-b-2 bg-secondary border-secondary-interact text-white;
}

.btn + .btn {
	@apply ml-1;
}

.btn--accept {
	@apply bg-primary border-primary-interact text-white;
}
.btn--decline {
	@apply bg-secondary border-secondary-interact text-primary;
}
