.actionbar--hidden {
	@apply: hidden;
}
.actionbar--minimized {
	@apply w-12 h-20;
}
.actionbar--minimized .actionbar__inner {
	@apply w-12;
}

.actionbar--collapsed {
	@apply h-screen w-28  transition-all;
}
.actionbar--collapsed .actionbar__inner {
	@apply w-24 pt-14 px-3.5;
}
.actionbar--collapsed .actionbar__link {
	@apply justify-center;
}
.actionbar--collapsed .actionbar__label {
	@apply hidden;
}
.actionbar--collapsed .actionbar__signout {
	@apply text-center;
}

.actionbar--expanded {
	@apply h-screen w-80;
}
.actionbar--expanded .actionbar__inner {
	@apply w-72 pt-15 px-5;
}
.actionbar--expanded .actionbar__arrow {
	@apply rotate-180;
}
.actionbar--expanded .actionbar__label {
	@apply block whitespace-nowrap;
}
.actionbar--expanded .actionbar__actions {
	@apply flex justify-between;
}
.actionbar_host-controls {
	display: none;
}
.actionbar--expanded .actionbar_host-controls {
	display: block;
}
