.scrollable-panel {
	height: 100%;
	max-height: 550px;

	@apply flex flex-col;
}

.scrollable-panel__viewport {
	@apply flex-grow overflow-y-auto pr-5 relative;
}
/*
.scrollable-panel__viewport::before {
	position: fixed;
	content: "";
	width: 100%;
	top: 0px;
	left: 0px;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.9) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	@apply h-3;
}
.scrollable-panel__viewport::after {
	position: fixed;
	content: "";
	width: 100%;
	bottom: 0px;
	left: 0px;
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0.9) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	@apply h-3;
}
*/
.stylized-scrollbar::-webkit-scrollbar {
	height: 3px; /* width of the entire scrollbar */
}
.stylized-scrollbar::-webkit-scrollbar-track {
	background-color: #e1e1e1; /* color of the tracking area */
	border-radius: 5px;
}
.stylized-scrollbar::-webkit-scrollbar-thumb {
	background-color: #a1a1a1;
	/* background-color: blue;  color of the scroll thumb */
	border-radius: 5px; /* roundness of the scroll thumb */
	/* border: 3px solid orange; creates padding around scroll thumb */
}

.scrollable-panel__viewport::-webkit-scrollbar {
	width: 8px; /* width of the entire scrollbar */
}
.scrollable-panel__viewport::-webkit-scrollbar-track {
	background-color: #e1e1e1; /* color of the tracking area */
	border-radius: 10px;
}
.scrollable-panel__viewport::-webkit-scrollbar-thumb {
	background-color: #a1a1a1;
	/* background-color: blue;  color of the scroll thumb */
	border-radius: 10px; /* roundness of the scroll thumb */
	/* border: 3px solid orange; creates padding around scroll thumb */
}
/*
.fancy-scrollbar::-webkit-scrollbar {
	background: transparent;
	margin:4px;
	position: relative;
	left: -4px;
}
.fancy-scrollbar::-webkit-scrollbar-track {
	background-color: transparent; 
	border-radius: 5px;
}
.fancy-scrollbar::-webkit-scrollbar-thumb {
	background-color: #a1a1a1;
	
	border-radius: 10px; 	
}*/

.fancy-scrollbar::-webkit-scrollbar {
	width: 14px;
	background-color: transparent;
  }
  
  /* 4px scroll thumb. 10px margin from right margin */
  .fancy-scrollbar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 14px 14px transparent;
	border: solid 4px transparent;
  }
  
  .fancy-scrollbar::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 14px 14px #bbbbbe;
	border: solid 4px transparent;
	border-radius: 14px;
  }
  
  .fancy-scrollbar::-webkit-scrollbar-button {
	display: none;
  }