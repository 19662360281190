.chat-panel .chat-panel__top {
	@apply text-white p-5 relative font-semibold my-2;
}
.chat-panel__messages {
	@apply p-2;
}
.chat-panel__messages-item {
	@apply mb-3 text-white block my-4 text-left flex;
	max-width: 70%;
}
.chat-panel__messages-item .img {
	@apply rounded-full bg-white w-16 h-16;
}

.bubble-wrap {
	@apply flex-grow;
}
.bubble {
	@apply rounded-lg p-4;
}

.timestamp {
	color: #555555;
	@apply text-sm;
	display: block;
}
.chat-panel__messages-item--theirs .bubble {
	@apply rounded-tl-none ml-2 text-gray-600;
	background-color: #e9e9e9;
}
.chat-panel__messages-item--theirs .timestamp {
	@apply text-right;
}

.chat-panel__messages-item--mine {
	@apply ml-auto flex-row-reverse;
}
.chat-panel__messages-item--mine .bubble {
	@apply rounded-tr-none mr-2 text-gray-600;

	background-color: #eebfc7;
}
.chat-panel__messages-item--mine .timestamp {
	@apply text-left;
}

.chat-panel__input {
	@apply rounded-md text-white flex p-2;
	background-color: #555555;
}
.chat-panel__input input,
.chat-panel__input textarea {
	@apply border-none;
}
.chat-panel__input input:focus,
.chat-panel__input textarea:focus {
	outline: none;
}

.chat-panel__content,
.chat-panel__input {
	@apply mx-5 my-2;
}
.chat-panel__input {
	@apply mb-4;
}
